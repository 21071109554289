import api from "./api.js";
import Directions from "./direction.js";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import moment from "moment";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale
);

var trip_term = localStorage.getItem("trip_term") || "Trip";
var driver_term = localStorage.getItem("driver_term") || "Driver";
var destination_term =
  localStorage.getItem("destination_term") || "Destination";

export default {
  components: { LineChart: Line },
  data: () => ({
    pageSizeManager: 1,
    searchManager: '',
    totalManagerItems: null,
    loading_manager: false,
    filteredManagerApprovals: [],
    options: {},
    approvalDialog: false,
    approvalStatus: null,
    itemToShow: [],
    imageDialog: false,
    selectedManagerRequest: null,
    manager_headers: [
      {
        text: driver_term,
        align: "center",
        value: "driver.full_name",
      },
      {
        text: "Destination Name",
        align: "start",
        value: "trip_destinations.destination_name",
      },
      /*{
        text: "Approval Type",
        value: "manager_approval",
        align: "start",
      },*/
      // {
      //   text: "Task",
      //   value: "task",
      //   align: "center",
      // },
      {
        text: "Approval Status",
        value: "manager_approval_status",
        align: "start",
      },
      {
        text: "Time Requested",
        value: "created_date",
        align: "start",
      },
      {
        text: driver_term + " Note",
        value: "note",
        align: "start",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
    lineChartData: {
      labels: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
      ],
      datasets: [
        {
          label: "Jobs Accomplished",
          data: [],
          fill: false,
          borderColor: "rgb(0, 100, 0)",
          tension: 0.1,
        },
        {
          label: "Jobs Pending",
          data: [],
          fill: false,
          borderColor: "rgb(200, 200, 0)",
          tension: 0.1,
        },
        {
          label: "Jobs Not Started",
          data: [],
          fill: false,
          borderColor: "rgb(70, 70, 90)",
          tension: 0.1,
        },
      ],
    },
    chartOptions: {
      responsive: true,
      suggestedMin: 0,
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1, // Force the ticks to increment by 1
          callback: function (value) {
            return Number.isInteger(value) ? value : null; // Show only integer values
          },
        },
      },
    },
    lineChartOptions: {
      responsive: true,
      scales: {
        x: {
          type: "category",
        },
        y: {
          type: "linear",
        },
      },
    },
    tenantType: sessionStorage.getItem("tenant_type") ?? null,
    zoom: (sessionStorage.getItem("tenant_type") ?? null) === "namat" ? 9 : 11,
    // center:
    //   (sessionStorage.getItem("tenant_type") ?? null) === "namat"
    //     ? { lat: 25.3548, lng: 51.1838 }
    //     : { lat: 25.0657, lng: 55.17128 },
    center: {
      lat: parseFloat(localStorage.getItem("lat") ?? "25.276987"),
      lng: parseFloat(localStorage.getItem("lon") ?? "55.17128"),
    },
    trip_term: trip_term,
    driver_term: driver_term,
    destination_term: destination_term,
    window_open: false,
    info_marker: null,
    infowindow: { lat: 10, lng: 10.0 },
    infoOriginWindow: { lat: 0, lng: 0 },
    trip_window: false,
    origin_window: false,
    selected_driver: null,
    driverName: null,
    info: null,
    selectedDestination: null,
    showOverlayScreen: false,
    order: 0,
    selectedMarker: null,
    directions: null,
    driver_id: -1,
    drivers: [],
    filter: null,
    isMouseOverMarkerOrInfoWindow: false,
    tripInfoDialog: false,
    items: [],
    filters: [
      { text: "Late", value: 3 },
      { text: "Early", value: 4 },
      { text: "Finished", value: 2 },
      { text: "Waiting", value: 5 },
    ],
    markers: [],
    allMarkers: [], // store all the driver markers
    trip_markers: [],
    origin_markers: [],
    valid: false,
    dialog: false,
    selectedObject: null,
    loading: true,
    driverInfo: false,
    search: "",
    snackbar: false,
    text: "",
    isrequired: true,
    noRules: [],
    show1: false,
    show2: false,
    email: null,
    fname: null,
    lname: null,
    myCurrentData: [],
    tdata: [],
    adata: [],
    prompt: "", // Data binding for the textarea
    generatedialog: false, // Control for dialog visibility
    today: null,
    theaders: [
      {
        text:
          (sessionStorage.getItem("tenant_type") ?? null) === "namat"
            ? "Staff"
            : driver_term + " Name",
        value: "driver.fullname",
        align: "center",
      },
      {
        text: "Status",
        value: "flag",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
      {
        text: trip_term + "s",
        value: "filtered_trips",
        align: "center",
      },
    ].filter(
      (th) =>
        !(
          th.text === trip_term + "s" &&
          (sessionStorage.getItem("tenant_type") ?? null) === "namat"
        )
    ),
    routes: [],
    filtered_trips: [],
    title: "",

    // this is for calendar and namat only
    type: "month",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: ["Repair", "Transfer", "Check"],
  }),

  async created() {
    this.$root.$on("location-updated", () => {
      this.center = {
        lat: parseFloat(localStorage.getItem("lat") ?? "25.276987"),
        lng: parseFloat(localStorage.getItem("lon") ?? "55.296249"),
      };
    });
    this.directions = new Directions(this.$refs); // Create the Direction instance
  },

  async mounted() {
    var myCurrentDate = new Date();
    this.today = myCurrentDate.toISOString().replace("T", " ").substr(0, 19);
    await api
      .GetDriversRecentLocations(this.today)
      .then((res) => {
        // this.loading = false;
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            if (element.locations.length != 0)
              this.allMarkers.push({
                id: element.locations[0].id,
                icon: require("@/assets/images/icons/bus.svg"),
                info: element.locations[0].date_and_time,
                driverName: element.fname + " " + element.lname,
                driver_id: parseInt(element.id),
                position: {
                  lat: parseFloat(element.locations[0].lat),
                  lng: parseFloat(element.locations[0].lon),
                },
              });
          });
        }
        this.markers = [...this.allMarkers];
      })
      .catch((error) => {
        this.error = error;
        // this.loading = false;
        this.errorDialog = true;
      });

    setInterval(() => {
      api
        .GetDriversRecentLocations(this.today)
        .then((res) => {
          if (res.data.responseCode === 1) {
            const temp = [];
            res.data.data.forEach((element) => {
              if (element.locations.length != 0)
                temp.push({
                  id: element.locations[0].id,
                  icon: require("@/assets/images/icons/bus.svg"),
                  info: element.locations[0].date_and_time,
                  driverName: element.fname + " " + element.lname,
                  driver_id: parseInt(element.id),
                  position: {
                    lat: parseFloat(element.locations[0].lat),
                    lng: parseFloat(element.locations[0].lon),
                  },
                });
            });
            this.allMarkers = [...temp];
          }
        })
        .catch((error) => {
          this.error = error;
          // this.loading = false;
          this.errorDialog = true;
        });
    }, 10000);

    await api
      .gettrips()
      .then((res) => {
        this.loading = false;
        if (res.data.responseCode === 1) {
          const data = res.data.data;
          const jobCounts = this.getJobStatusData([...data]);

          const lineChartData = {
            labels: Object.keys(jobCounts),
            datasets: [
              {
                label: "Jobs Accomplished",
                data: Object.values(jobCounts).map((count) => count.completed),
                fill: false,
                borderColor: "rgb(0, 100, 0)",
                tension: 0.4,
              },
              {
                label: "Jobs Pending",
                data: Object.values(jobCounts).map((count) => count.pending),
                fill: false,
                borderColor: "rgb(200, 200, 0)",
                tension: 0.4,
              },
              {
                label: "Jobs Not Started",
                data: Object.values(jobCounts).map((count) => count.notStarted),
                fill: false,
                borderColor: "rgb(70, 70, 90)",
                tension: 0.1,
              },
            ],
          };

          this.lineChartData = lineChartData;

          res.data.data.forEach((element) => {
            element.driver = {
              fullname: element.driver.fname + " " + element.driver.lname,
              id: element.driver.id,
            };

            if (element.started_date != null) {
              if (
                this.convertUTCDateToLocalDate(element.started_date) >
                this.convertUTCDateToLocalDate(element.start_date)
              ) {
                // Late
                element.status = 1;
              } else {
                element.status = 2; // Early
              }
            } else {
              element.status = 0; // Not yet
            }

            // Determine the status based on the flag and tenantType
            element.flag = (() => {
              if (element.flag === 0) {
                return this.tenantType === "namat" ? "Assigned" : "Not Picked";
              }
              if (element.ended_date) {
                return this.tenantType === "namat" ? "Done" : "Delivered";
              }
              return this.tenantType === "namat" ? "In progress" : "Picked";
            })();

            // Format the ended_date with appropriate status
            element.ended_date = element.ended_date
              ? this.convertUTCDateToLocalDate(element.ended_date)
              : element.started_date
              ? "On route..."
              : "Waiting...";

            // Format the started_date with appropriate status
            element.started_date = element.started_date
              ? this.convertUTCDateToLocalDate(element.started_date)
              : "Waiting...";

            element.start_date = this.convertUTCDateToLocalDate(
              element.start_date
            );
            element.created_date = this.convertUTCDateToLocalDate(
              element.created_date
            );

            this.tdata.push(element);
          });
        }
      })
      .catch((error) => {
        this.text = error;
        this.snackbar = true;
        this.loading = false;
        this.errorDialog = true;
      })
      .finally(() => {
        this.filtered_trips = this.tdata.filter((trip) => {
          return trip.flag !== "Delivered" && trip.started_date !== "Waiting...";
        });
      
        this.generateDirections(this.filtered_trips);

        let origin = null;
        if (this.filtered_trips.length > 0) {
            origin = this.getTripOrigin(this.filtered_trips[0]);
        } else {
          origin = this.getTripOrigin(this.tdata[0]);
        }
      
        if (origin) {
          this.center = origin;
        }
      
      });


  },

  computed: {
    filteredItems() {
      return this.tdata.filter((i) => {
        return !this.filter || i.status === this.filter;
      });
    },

    column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 12;
        case "lg":
          return 6;
        case "xl":
          return 6;
      }
    },
  },

  watch: {
    filtered_trips(newFilteredTrips) {
      this.generateDirections(newFilteredTrips);
      this.updateMarkers(newFilteredTrips);
    },
    allMarkers() {
      this.updateMarkers();
    },
  },

  methods: {
    refreshManagerDashboard(fromIcon = "") {
      if(fromIcon == 'icon'){
        this.searchManager = "";
      }
      this.loading_manager = true;
      this.fetchManagerRequest().finally(() => {
        this.loading_manager = false;
      });
     
    },
    format_date(dateInput) {
      if (!dateInput) return null;
    
      let date;
    
      if (typeof dateInput === 'string') {
        date = new Date(dateInput);
      } else if (dateInput instanceof Date) {
        date = dateInput;
      } else {
        return null;
      }
    
      if (isNaN(date.getTime())) {
        return null;
      }
    
      return date.toLocaleString();
    },
    async updateRequest() {
      api
        .updateManagerRequest(this.selectedManagerRequest, this.approvalStatus)
        .then((res) => {
          if (res.data.responseCode) {
            this.refreshManagerDashboard();
            this.approvalDialog = false;
            this.text = "Approval updated successfully";
            this.snackbar = true;
          }else{
            throw new Error(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.text = error;
          this.snackbar = true;
          this.errorDialog = true;
        });

      this.approvalDialog = false;
    },

    openImageModal(images) {
      if (images) {
        this.itemToShow = Array.isArray(images) ? images : [images];
      } else {
        this.itemToShow = [];
      }
      this.imageDialog = true;
    },

    openApprovalModal(item) {
      this.itemToShow = item;
      this.selectedManagerRequest = item.id;
      this.approvalDialog = true;
    },
    async fetchManagerRequest() {
      this.loading_manager = true;
      api
        .getManagerApproval("pending", this.currentManagerPage, this.pageSizeManager, this.searchManager)
        .then((res) => {
          if (res.data.success) {
            if(!res.data.data) this.filteredManagerApprovals = [];
            else{ 
              this.filteredManagerApprovals = res.data.data.map((approval) =>{
                return {
                  ...approval,
                  driver: {full_name: approval.driver.fname + " " + approval.driver.lname},
                  destination_name: (approval.destination_name && approval.destination_name !== "0") ? approval.destination_name : "Unknown " + destination_term,
                  created_date: this.format_date(approval.created_date),
                  manager_approval_status: 
                    approval.manager_approval_status
                    .replaceAll("_", " ")
                    .replace(/\b\w/g, char => char.toUpperCase()),
                  note: !approval.note || approval.note == "" ? "No note provided" : approval.note,
                   
                }
                
              });
            }
            
            this.totalManagerItems = res.data.pagination.total; 
          }
          this.loading_manager = false;
        })
        .catch((error) => {
          this.text = error;
          this.snackbar = true;
          this.errorDialog = true;
          this.loading_manager = false;
        });
    },
    onPaginationManager(options) {
      this.currentManagerPage = options.page;
      this.pageSizeManager = options.itemsPerPage;
      this.fetchManagerRequest();
    },
    getApprovalStatusIcon(status) {
      if (status === 'pending') return 'mdi-timer-sand';
      if (status === 'accepted') return 'mdi-check-circle';
      if (status === 'awaiting_submission') return 'mdi-progress-clock';
      return 'mdi-alert-circle';
    },
    getApprovalStatusColor(status) {
      if (status === 'pending') return 'blue';
      if (status === 'accepted') return 'green';
      if (status === 'awaiting_submission') return 'gray';
      return 'red';
    },
    formatApprovalStatus(status) {
      return status.replaceAll("_", " ").replace(/\b\w/g, char => char.toUpperCase());
    },
    showOverlay(destination, index){
      this.selectedDestination = destination;
      this.showOverlayScreen = true;
      this.selectedDestination.index = index + 1;
    },
    getTripOrigin(trip) {
      const is_origin = trip.warehouse.lat !== null && trip.warehouse.lat !== undefined;
      if (is_origin) {
        return {
          lat: parseFloat(trip.warehouse.lat),
          lng: parseFloat(trip.warehouse.lon),
        };
      }else {
        return null;
      }
    },

    updateMarkers() {
      this.markers = [];
      this.markers = this.allMarkers.filter((marker) => {
        return this.filtered_trips.some(
          (trip) => trip.driver.id == marker.driver_id
        );
      });
    },
    showDialog(item) {
      this.selectedObject = item;
      this.tripInfoDialog = true;
    },

    generateTask() {
      // Clear the textarea
      this.prompt = "";
      // Open the dialog
      this.generatedialog = true;
    },

    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }

      return utcDate;
    },

    getJobStatusData(trips = []) {
      const jobCounts = {};

      // jobCounts for every 30-minute interval of the day
      for (let hour = 0; hour < 24; hour++) {
        const hourStr = hour.toString().padStart(2, "0");
        jobCounts[`${hourStr}:00`] = {
          completed: 0,
          pending: 0,
          notStarted: 0,
        };
        jobCounts[`${hourStr}:30`] = {
          completed: 0,
          pending: 0,
          notStarted: 0,
        };
      }
      function getHourLabel(dateString) {
        let local = dateString;

        if (dateString) {
          local = moment.utc(dateString).local().format("YYYY-MM-DD HH:mm:ss");
        }

        const date = new Date(local);
        const hour = date.getHours();
        return `${hour}:00`;
      }

      const todayUtc = moment.utc(new Date()).format("YYYY-MM-DD");

      trips.forEach((trip) => {
        // make sure the trip is created today
        const tripDateUtc = moment.utc(trip.created_date).format("YYYY-MM-DD");
        // if the trip is not from today skip
        if (tripDateUtc !== todayUtc) {
          return;
        }

        const start_label = getHourLabel(trip.start_date);
        const end_label = getHourLabel(trip.ended_date);

        if (!jobCounts[start_label] && !jobCounts[end_label]) {
          return; // skip if the hour label is not in the jobCounts
        }

        if (trip.ended_date && trip.flag === 1) {
          jobCounts[end_label].completed += 1;
        } else if (trip.started_date) {
          jobCounts[start_label].pending += 1;
        } else {
          jobCounts[start_label].notStarted += 1;
        }
      });

      return jobCounts;
    },

    getFiltered(e) {
      this.myCurrentData = e;
    },

    async focus(driverid) {
      this.selectedMarker = null;

      for (let index = 0; index < this.markers.length; index++) {
        var element = this.markers[index];
        if (element.driver_id == driverid) {
          this.selectedMarker = element;
        }
      }

      if (this.selectedMarker == null) {
        this.text = this.driver_term + " has no location to share";
        this.snackbar = true;
      } else {
        if (this.$refs.mapRef && this.$refs.mapRef.$mapObject) {
          this.$refs.mapRef.$mapObject.panTo(this.selectedMarker.position);
        }
      }
    },

    focusRoute(trip_id, color) {
      let curr_route = null;

      for (let index = 0; index < this.routes.length; index++) {
        var element = this.routes[index];
        if (element.trip_id === parseInt(trip_id)) {
          curr_route = element;
        }
      }

      if (curr_route != null) {
        const tripInFiltered = this.filtered_trips.some(
          (trip) => trip.id === curr_route.trip_id
        );

        if (!tripInFiltered && !color) {
          this.directions.reset();
          return;
        }

        this.directions.drawTripLine(
          curr_route.origin,
          curr_route.destination,
          curr_route.waypoints,
          color
        );
      }
    },

    isInFilteredTrips(item) {
      return this.filtered_trips.includes(item);
    },

    toggleFilteredTrip(item) {
      if (this.isInFilteredTrips(item)) {
        this.filtered_trips = this.filtered_trips.filter(
          (trip) => trip !== item
        );
      } else {
        this.filtered_trips.push(item);
      }
    },

    openWindow(index, driverName, info) {
      this.infowindow = this.markers[index].position;
      this.driver_id = this.markers[index].driver_id;
      this.driverName = driverName;
      this.info = this.convertUTCDateToLocalDate(info);
      this.window_open = true;
      this.trip_window = false;
      this.origin_window = false;
    },

    openTripWindow(index, trip) {
      this.infowindow = trip.position;
      this.driver_id = trip.driver_id;
      this.driverName = trip.driver.fullname;
      this.info = trip.destination?.name ?? trip.destination_name;
      this.order = trip.order;
      console.log(this.trip_markers);
      this.trip_window = true;
      this.origin_window = false;
    },

    onMarkerMouseOver(index, driverName, info) {
      this.infowindow = this.markers[index].position;
      this.driver_id = this.markers[index].driver_id;
      this.driverName = driverName;
      this.info = this.convertUTCDateToLocalDate(info);
      this.window_open = true;
      this.trip_window = false;
      this.origin_window = false;
      this.isMouseOverMarkerOrInfoWindow = true;
    },

    openOriginWindow(index) {
      this.infoOriginWindow = this.origin_markers[index].position;
      this.title = this.origin_markers[index].title;
      this.window_open = false;
      this.trip_window = false;
      this.origin_window = true;
    },

    generateDirections(trips) {
      if (!trips) return;

      if (trips.length === 0) {
        this.directions.reset();
      }

      this.trip_markers = [];
      this.origin_markers = [];

      trips.map((element) => {
        const waypoints = [];
        const is_origin =
          element.warehouse.lat !== null && element.warehouse.lat !== undefined;

        element.destinations.map(async (d, index) => {
          const lat = d.destination?.lat
            ? d.destination?.lat
            : d.destination_lat;
          const lon = d.destination?.lon
            ? d.destination?.lon
            : d.destination_lon;

          this.trip_markers.push({
            ...element,
            ...d,
            order: index + 1,
            trip_id: element.id,
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lon),
            },
          });

          if (
            (index === 0 && is_origin) ||
            (index !== 0 && index !== element.destinations.length - 1)
          ) {
            waypoints.push({
              location: {
                lat: parseFloat(lat),
                lng: parseFloat(lon),
              },
              stopover: true,
            });
          }

          if (is_origin) {
            this.origin_markers.push({
              trip_id: element.id,
              title: element.warehouse.title,
              position: {
                lat: parseFloat(element.warehouse.lat),
                lng: parseFloat(element.warehouse.lon),
              },
            });
          }

          if (index === element.destinations.length - 1) {
            const last_destination_lat = d.destination?.lat
              ? d.destination?.lat
              : d.destination_lat;
            const last_destination_lon = d.destination?.lon
              ? d.destination?.lon
              : d.destination_lon;

            const first_destination = element.destinations[0];
            const first_destination_lat = first_destination.destination?.lat
              ? first_destination.destination?.lat
              : first_destination.destination_lat;
            const first_destination_lon = first_destination.destination?.lon
              ? first_destination.destination?.lon
              : first_destination.destination_lon;

            const origin = {
              lat: is_origin ? element.warehouse.lat : first_destination_lat,
              lng: is_origin ? element.warehouse.lon : first_destination_lon,
            };

            const destination = {
              lat: last_destination_lat,
              lng: last_destination_lon,
            };

            await this.directions.drawTripLine(origin, destination, waypoints);
            this.routes.push({
              trip_id: element.id,
              driver_id: element.driver.id,
              origin,
              destination,
              waypoints,
            });
          }
        });
      });
    },

    onMarkerMouseOut() {
      this.isMouseOverMarkerOrInfoWindow = false;
      setTimeout(() => {
        if (!this.isMouseOverMarkerOrInfoWindow) {
          this.window_open = false;
        }
      }, 200);
    },

    onInfoWindowMouseOver() {
      this.isMouseOverMarkerOrInfoWindow = true;
    },

    onInfoWindowMouseOut() {
      this.isMouseOverMarkerOrInfoWindow = false;
      setTimeout(() => {
        if (!this.isMouseOverMarkerOrInfoWindow) {
          this.window_open = false;
        }
      }, 200);
    },

    // namat methods
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events;
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },

  getSiteIcon() {
    return require("@/assets/images/icons/bus.svg");
  },
};
